<a href="javascript:void(0)" class="link_btn" (click)="openGuidelinesModal()" data-mdb-toggle="modal"
	data-mdb-target="#guidlineModal">View Guideline</a>


<!-- Guidline Modal -->
<div class="modal fade" bsModal #guidlineModal="bs-modal" id="guidlineModal" tabindex="-1"
	aria-labelledby="guidlineModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="guidlineModalLabel">Abstract Guidelines</h5>
				<button type="button" class="btn-close" (click)="guidlineModal.hide()" data-mdb-dismiss="modal"
					aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="static">
					<p style="line-height:1.2; background-color:#ffffff; margin-bottom:19px"><span
							style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
								style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
										style="font-weight:400"><span style="font-style:normal"><span
												style="text-decoration:none">We kindly ask you to read these guidelines
												before submitting your
												abstract.&nbsp;</span></span></span></span></span></span></p>

					<ul>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">You must be a registered delegate to
													present at the conference if your abstract is accepted for
													presentation.&nbsp;</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Registration for the conference is not
													mandatory for the submission of an
													abstract.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">If you have any queries, please contact
													the Scientific Secretariat
													at&nbsp;</span></span></span></span></span></span><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#0000ff"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">secretary@isg.org.in</span></span></span></span></span></span>
						</li>
					</ul>

					<p style="line-height:1.2; background-color:#ffffff; margin-bottom:19px"><span
							style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
								style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
										style="font-weight:700"><span style="font-style:normal"><span
												style="text-decoration:none">Topics for Abstract
												submission</span></span></span></span></span></span></p>

					<ul>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Esophagus</span></span></span></span></span></span>
						</li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Stomach</span></span></span></span></span></span>
						</li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Small
													intestine</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Large
													intestine</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Liver</span></span></span></span></span></span>
						</li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Biliary
													tract</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Pancreas</span></span></span></span></span></span>
						</li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Endoscopy</span></span></span></span></span></span>
						</li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Motility
													disorders</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Pediatric
													gastroenterology</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Surgical
													gastroenterology</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Nutrition</span></span></span></span></span></span>
						</li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Miscellaneous</span></span></span></span></span></span>
						</li>
					</ul>

					<p style="line-height:1.2; background-color:#ffffff; margin-bottom:19px"><span
							style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
								style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
										style="font-weight:700"><span style="font-style:normal"><span
												style="text-decoration:none">Important
												dates</span></span></span></span></span></span></p>

					<ul>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Submission Closing: June 30,
													2024</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Notification of abstract
													acceptance:&nbsp; July 31,
													2024</span></span></span></span></span></span></li>
					</ul>

					<p style="line-height:1.3800000000000001"><span
							style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
								style="font-family:Candara,sans-serif"><span style="color:#333333"><span
										style="font-weight:400"><span style="font-style:normal"><span
												style="text-decoration:none">Following are the session-wise requirements
												for the consideration of your paper
												&ndash;</span></span></span></span></span></span></p>

					<ul>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:700"><span style="font-style:normal"><span
													style="text-decoration:none">Plenary and Young Investigator
													Session:</span></span></span></span></span></span><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none"> Upload the full manuscript including
													the abstract, figures, and tables in a single file in PDF format.
													The paper won&#39;t be taken into consideration for the Plenary and
													Young investigator session if
												</span></span></span></span></span></span><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:700"><span style="font-style:normal"><span
													style="text-decoration:none">the full manuscript is
													missing.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:700"><span style="font-style:normal"><span
													style="text-decoration:none">Free Paper
													Sessions (Oral/
													Poster):</span></span></span></span></span></span><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none"> Submit the abstract online on the ISG
													Abstract portal.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:700"><span style="font-style:normal"><span
													style="text-decoration:none">Video Presentation
													(Endoscopy):</span></span></span></span></span></span><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none"> Submit your video for endoscopy video
													session&nbsp;</span></span></span></span></span></span>

							<p style="line-height:1.3800000000000001"><span
									style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
										style="font-family:Candara,sans-serif"><span style="color:#333333"><span
												style="font-weight:400"><span style="font-style:normal"><span
														style="text-decoration:none">All video submissions should
														include: &ndash;</span></span></span></span></span></span></p>
							<ul>
								<li aria-level="2"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">a clear introduction with a
															background explaining why the video is relevant or
															important, the scientific or educational aims, and the
															rationale for performing any technique or intervention
															depicted;</span></span></span></span></span></span></li>
								<li aria-level="2"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">for novel interventions a brief
															description of why the approach was justified and
															alternatives that were considered should be
															included;</span></span></span></span></span></span></li>
								<li aria-level="2"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">the video should clearly show
															the findings or technique using high-quality video;
															low-quality video with excessive movement or image artefact
															should be avoided and may lead to rejection of the
															manuscript;</span></span></span></span></span></span></li>
								<li aria-level="2"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">diagrams to assist the viewer
															in understanding the conceptual basis of a technique may be
															beneficial;</span></span></span></span></span></span></li>
								<li aria-level="2"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">a summary of the findings
															should be included at the end of the video, explaining the
															outcomes and the relevance of the video to current or future
															practice. Authors should aim to demonstrate their findings
															and educate
															viewers;</span></span></span></span></span></span></li>
								<li aria-level="2"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">disclosures and conflicts of
															interest for all
															authors;</span></span></span></span></span></span></li>
								<li aria-level="2"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">audio narration is encouraged.
															If audio narration is included this must be in English.
															Please speak clearly and at a slow medium rate of
															speech.</span></span></span></span></span></span></li>
								<li aria-level="2"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Title: The length of the title
															should not exceed 40 words including
															spaces.</span></span></span></span></span></span></li>
							</ul>

						</li>
					</ul>

					<p style="line-height:1.3800000000000001"><span
							style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
								style="font-family:Candara,sans-serif"><span style="color:#333333"><span
										style="font-weight:700"><span style="font-style:normal"><span
												style="text-decoration:none">Guidelines</span></span></span></span></span></span>
					</p>

					<p style="line-height:1.2"><span
							style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
								style="font-family:Candara,sans-serif"><span style="color:#333333"><span
										style="font-weight:400"><span style="font-style:normal"><span
												style="text-decoration:none">Authors are requested to adhere to the
												following guidelines while preparing their abstracts. This will greatly
												facilitate the review process.</span></span></span></span></span></span>
					</p>

					<ul>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:700"><span style="font-style:normal"><span
													style="text-decoration:none">Abstracts submitted will be published
													in the supplement issue of the Indian Journal of
													Gastroenterology.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Abstracts must be original and must not
													have been published earlier or accepted for publication. If
													presented earlier in any other conference, please make a
													mention.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">All abstracts must be submitted in
													English.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Abstracts submitted for presentation
													will be published exactly as received and should be checked for
													spelling and grammar prior to submission. It is the responsibility
													of the presenter/author to ensure that the abstract is submitted
													correctly.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">The full title of the abstract should
													be filled up in sentence
													case.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Please provide the complete details of
													the presenting and corresponding author while submitting the
													abstract.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Please put all the authors&rsquo; names
													and institution names respectively in the sequence you would like to
													be presented in the abstract
													book.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">The length of the abstract body should
													not exceed 300 words. The online submission module will not accept
													abstracts that exceed this word
													limit.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Please upload the complete abstract
													file in .doc file (including Title*, Authors*, Institution* and
													Abstract*).</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Abstract Title &ndash; Do not use any
													abbreviations.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Please mention 3-4 keywords per
													abstract. Keywords should be arranged in alphabetic
													order.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Reference list entries should be
													numbered consecutively. Please include the names of all authors if
													up to six; if the number of authors exceeds 6, only the first 3
													authors&rsquo; names should be listed, followed by et
													al.</span></span></span></span></span></span>
							<ul>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#2b2b2b"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Article
															title</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#2b2b2b"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Abbreviated Journal
															title</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#2b2b2b"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Year</span></span></span></span></span></span>
								</li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#2b2b2b"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Volume
															number</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#2b2b2b"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Page numbers - first and
															last</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#2b2b2b"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Guzman-Prado Y, Samson O, Segal
															JP, Limdi JK, Hayee B. Vitamin D therapy in adults with
															inflammatory bowel disease: A systematic review and
															meta-analysis. Inflamm Bowel Dis.
															2020;26:1819-30.</span></span></span></span></span></span>
								</li>
							</ul>
						</li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">You can revise the submitted abstract
													online till June 30, 2024</span></span></span></span></span></span>
						</li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#333333"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">After the closure of the abstract
													submission date, the editing option will not be
													available.</span></span></span></span></span></span></li>
					</ul>

					<p style="line-height:1.2"><span
							style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
								style="font-family:Candara,sans-serif"><span style="color:#333333"><span
										style="font-weight:400"><span style="font-style:normal"><span
												style="text-decoration:none">Acceptance of abstracts by the Scientific
												Committee does not imply permission to present the work at the
												conference. Presenting authors must register for the conference and pay
												the registration fees to attend the conference and make their
												presentations.</span></span></span></span></span></span></p>


					<p style="line-height:1.2; background-color:#ffffff; margin-bottom:19px"><span
							style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
								style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
										style="font-weight:400"><span style="font-style:normal"><span
												style="text-decoration:none"><b>ISGCON: Guidelines for Video submissions
													(Video Digest Session: Endoscopy) should
													include:</b>.&nbsp;</span></span></span></span></span></span></p>
					<ul>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">A clear introduction with a background
													explaining why the video is relevant or important, the scientific or
													educational aims, and the rationale for performing any technique or
													intervention
													depicted.&nbsp;</span></span></span></span></span></span></li>

						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">For novel interventions a brief
													description of why the approach was justified and alternatives
													that were considered should be
													included.&nbsp;</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">The video should clearly show the
													findings or technique using high-quality video; low-quality
													video with excessive movement or image artifact should be
													avoided and may lead to
													rejection.&nbsp;</span></span></span></span></span></span></li>

						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Diagrams to assist the viewer in
													understanding the conceptual basis of a technique may be
													beneficial.&nbsp;</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">A summary of the findings should be
													included at the end of the video, explaining the outcomes and
													the relevance of the video to current or future practice.
													Authors should aim to demonstrate their findings and educate
													viewers.&nbsp;</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Disclosures and conflicts of
													interest for all
													authors.&nbsp;</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:disc"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Audio narration in English is
													required. Speak clearly and at a medium
													pace.&nbsp;</span></span></span></span></span></span></li>
					</ul>


					<p style="line-height:1.2; background-color:#ffffff; padding:0pt 0pt 14pt 0pt"><span
							style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
								style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
										style="font-weight:700"><span style="font-style:normal"><span
												style="text-decoration:none">Technical
												Guidelines&nbsp;</span></span></span></span></span></span></p>

					<p style="line-height:1.2; background-color:#ffffff; margin-bottom:19px"><span
							style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
								style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
										style="font-weight:400"><span style="font-style:normal"><span
												style="text-decoration:none">(DO NOT CLICK ON THE BACK BUTTON ON THE
												BROWSER DURING REGISTRATION &ndash; YOU MIGHT LOSE ALL YOUR
												DATA)</span></span></span></span></span></span></p>

					<ol>
						<li aria-level="1" style="list-style-type:decimal"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Account creation: Account creation is
													mandatory to submit an abstract. If you forgot your password, you
													may request to send a reminder email. Please ensure to use the same
													email address as registered on the
													website.</span></span></span></span></span></span></li>
						<li aria-level="1" style="list-style-type:decimal"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">Submission of an
													abstract:</span></span></span></span></span></span>
							<ul>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Items marked with an asterisk
															(*) are mandatory. The system will inform you if you have
															not fulfilled all the mandatory
															fields.</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Do not repeat the title of your
															abstract, authors&#39; names, or headers of each field. It
															will be automatically added to your
															abstract.</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Please ensure that your
															abstract does not contain spelling, grammatical or
															scientific errors. The abstract&rsquo;s text will be
															reproduced exactly as submitted. No proofreading will be
															done.&nbsp;</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">The format will be unified for
															all abstracts.</span></span></span></span></span></span>
								</li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Make sure to carefully check
															your abstract preview before the submission. Once you have
															completed all the required information, you will be able to
															submit your abstract using the &quot;Proof and submit&rdquo;
															button which will appear at the bottom of the
															page.</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Abstract once submitted cannot
															be modified.</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Do not open your abstract
															submission on multiple devices as they automatically back up
															every 5 minutes. This may erase data entered from another
															device.</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">You will receive a confirmation
															email after the abstract submission with a preview of the
															abstract attached to the
															email.</span></span></span></span></span></span></li>
							</ul>
						</li>
						<li aria-level="1" style="list-style-type:decimal"><span
								style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
									style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
											style="font-weight:400"><span style="font-style:normal"><span
													style="text-decoration:none">List of
													authors:</span></span></span></span></span></span>
							<ul>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Submitting author: A person who
															submits the abstract and who will receive all the
															communications about the
															presentation.</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Presenting author: A person who
															will present the abstract at the meeting and also be marked
															as the presenter in the Congress
															programme.</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Co-author: A cited co-author is
															a person who scientifically endorses the abstract and is
															therefore responsible for its
															content.</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">By clicking the &quot;Add
															author&quot; button, you can add co-authors to your
															abstract. The first author is automatically already entered
															but you can modify
															it.</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Please enter your full name
															(First, Middle, and Last name) in lowercase with the first
															letter
															capitalized.</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">You can change the order of
															authors by rearranging the
															numbers.</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Conflict of interests: Authors
															and co-authors must declare any financial or any other
															interests of any company in connection with the
															study.</span></span></span></span></span></span></li>
								<li aria-level="2" style="list-style-type:disc"><span
										style="font-size:10pt; font-variant:normal; white-space:pre-wrap"><span
											style="font-family:Candara,sans-serif"><span style="color:#4c505a"><span
													style="font-weight:400"><span style="font-style:normal"><span
															style="text-decoration:none">Once the author submits the
															abstract, all the co-authors will be notified by
															email.</span></span></span></span></span></span></li>
							</ul>
						</li>
					</ol>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn app_btn btn_close" (click)="guidlineModal.hide()"
					data-mdb-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
<!--/. Guidline Modal -->