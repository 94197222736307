import { RestService } from './../../core/rest.service';
import {
  Component,
  OnInit,
  ViewChild,
  NgZone,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';

import { CommunicationService } from 'src/services/communication.service';
import { AbstractService } from 'src/services/abstract.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
@Component({
  selector: 'login',
  styleUrls: ['./login.component.css'],
  templateUrl: './login.component.html',
  providers: [AuthService, RestService],
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('aboutModal', { static: true })
  public aboutModal: ModalDirective;
  @ViewChild('secretariatModal', { static: true })
  public secretariatModal: ModalDirective;
  public localState = { value: '' };
  validateEmailform: FormGroup;

  // TO MAKE RESPONSIVE
  isCollapsed: boolean = true;
  lastUrl: any;
  public auth2: any;
  googleEmail: any;
  googleToken: any;
  config = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: true,
  };
  loginRequired: string;
  loginMinLength: string;
  loginMaxLength: string;
  loginEmailInvalid: string;
  passwordRequired: string;
  passwordMaxLength: string;
  oneTimePasswordFormGroup: FormGroup;
  isShowOtpFormGroup: boolean = false;
  isUserNotRegistered: boolean = false;
  isRegistrationRequired: boolean = false;
  registerCheckboxValue: boolean = false;
  modalRef: any;
  conferenceShortName: string;
  eventShortName: string;

  isUserAlredyExists = false;
  validatePasswordform: FormGroup;
  isShowOtpVerifyScreen;
  showInvalidOtp;
  otpValues = Array(4);
  clipData;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public ngZone: NgZone,
    public cookieService: CookieService,
    public communicationService: CommunicationService,
    private abstractService: AbstractService
  ) {
    this.lastUrl = this.cookieService.get('lastUrl');
    this.deleteAllCookies();
    this.conferenceShortName = this.route.snapshot.queryParamMap.get(
      'conference'
    )
      ? this.route.snapshot.queryParamMap.get('conference')
      : 'IEEE-9';
    this.eventShortName = this.route.snapshot.queryParamMap.get('event')
      ? this.route.snapshot.queryParamMap.get('event')
      : 'event3';

    // location.replace("https://isshp2023.mypage.acad360.com/logout");
  }
  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }
  public ngOnInit() {
    this.validateEmailform = new FormGroup({
      Email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ]),
    });

    this.validatePasswordform = new FormGroup({
      Password: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
      ]),
    });
    this.oneTimePasswordFormGroup = new FormGroup({
      input1: new FormControl('', [Validators.required]),
      input2: new FormControl('', [Validators.required]),
      input3: new FormControl('', [Validators.required]),
      input4: new FormControl('', [Validators.required]),
    });

    this.otpFromChanges();
  }

  public posterPanelList(userId, adminAuthority, userName) {
    this.abstractService.getPosterPanelList().subscribe((res) => {
      var posterAdminAuthority = false;
      if (res.response.entries) {
        const reviewerList = res.response.entries;
        reviewerList.forEach((element) => {
          if (element.shortName === userId) {
            posterAdminAuthority = true;
            // this.setCookieAndNavigate(
            //   userId,
            //   adminAuthority,
            //   userName,
            //   posterAdminAuthority
            // );
          } else {
            //  this.cookieService.set('posterAdmin', 'false' );
            // this.setCookieAndNavigate(
            //   userId,
            //   adminAuthority,
            //   userName,
            //   posterAdminAuthority
            // );
          }
        });
      } else {
        // this.cookieService.set('posterAdmin', 'false' );
        // this.setCookieAndNavigate(
        //   userId,
        //   adminAuthority,
        //   userName,
        //   posterAdminAuthority
        // );
      }
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  goToForgotPassword() {
    this.router.navigate(['/forgotpassword']);
  }
  setCookieAndNavigate(userId, adminAuthority, userName) {
    this.communicationService.setUserId(userId);
    this.communicationService.setAdminAuthority(adminAuthority);
    this.communicationService.setUserName(userName);
    // this.communicationService.setPosterAdminAuthority(posterAdminAuthority);
    if (this.lastUrl) {
      this.router.navigateByUrl(this.lastUrl);
      this.cookieService.delete('lastUrl');
    } else {
      this.router.navigate(['/isgcon2024/abstract/mysubmissions']);
    }
  }

  ngOnDestroy() {
    if (this.aboutModal) {
      this.aboutModal.hide();
    }
    if (this.secretariatModal) {
      this.secretariatModal.hide();
    }
  }
  public deleteAllCookies() {
    const cookies = document.cookie.split(';');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (name !== 'lastUrl') {
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
    }
  }
  // as per new ui
  onValidateEmail(values) {
    if (this.validateEmailform.valid) {
      const email = values.Email;
      this.authService.userAlreadyExists(email).subscribe((res) => {
        if (res.response.list.entries.length !== 0) {
          this.isUserAlredyExists = true;
        } else {
          this.router.navigate(['/isgcon2024/register'], {
            queryParams: { email },
          });
        }
      });
    } else {
      this.validateAllFormFields(this.validateEmailform);
    }
  }
  onValidatePassword(values) {
    if (this.validatePasswordform.valid) {
      const email = this.validateEmailform.value.Email;
      const password = this.validatePasswordform.value.Password;
      this.authService.login(email, password).subscribe(
        (res) => {
          this.setCookieAndNavigate(
            res.response.userId,
            res.response.adminAuthority,
            res.response.userName
          );
          // this.posterPanelList(
          //   res.response.userId,
          //   res.response.adminAuthority,
          //   res.response.userName
          // );
        },
        (err) => {}
      );
    } else {
      this.validateAllFormFields(this.validatePasswordform);
    }
  }
  loginUsingOtp() {
    const data = {
      password: '',
      userId: this.validateEmailform.value.Email,
    };
    this.authService
      .otpAuthorization(data, 'GENERATE', false)
      .subscribe((res) => {
        if (res.response === null) {
          this.isUserAlredyExists = null;
          this.isShowOtpVerifyScreen = true;
        }
      });
  }
  otpFromChanges() {
    this.oneTimePasswordFormGroup.valueChanges.subscribe((value) => {
      if (this.oneTimePasswordFormGroup.valid) {
        this.showInvalidOtp = false;
      }
    });
  }
  public onSubmitOtpFormGroup(value) {
    if (this.oneTimePasswordFormGroup.valid) {
      const data = {
        password:
          value.input1 +
          '' +
          value.input2 +
          '' +
          value.input3 +
          '' +
          value.input4,
        userId: this.validateEmailform.value.Email,
      };
      this.authService
        .otpAuthorization(data, 'VERIFY', this.isRegistrationRequired)
        .subscribe((res) => {
          if (res.response) {
            // this.posterPanelList(
            //   res.response.userId,
            //   res.response.adminAuthority,
            //   res.response.userName
            // );
            this.setCookieAndNavigate(
              res.response.userId,
              res.response.adminAuthority,
              res.response.userName
            );
          }
        });
    } else {
      this.showInvalidOtp = true;
    }
  }
  openAboutMoodal() {
    this.aboutModal.config = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
    };
    this.aboutModal.show();
  }
  openSecretariatModal() {
    this.secretariatModal.config = {
      animated: true,
      backdrop: true,
      ignoreBackdropClick: true,
      keyboard: true,
    };
    this.secretariatModal.show();
  }
  otpOneCall(event, index) {
    console.log(event.target);

    const data = event.target.form['otp' + (index - 1)];
    if (event.code === 'Backspace' && this.hasNoValue(index)) {
      if (index > 0) {
        event.target.form['otp' + (index - 1)].focus();
      }
    } //else if any input move focus to next or out
    else if (event.target.value !== '') {
      index < 3
        ? event.target.form['otp' + (index + 1)].focus()
        : event.target.blur();
    }
    this.otpValues[index] = event.target.value;
  }

  hasNoValue(index) {
    if (this.otpValues[index] || this.otpValues[index] === 0) return false;

    return true;
  }
  otpInputs(event, index) {
    if (event.target.value > 10) {
      event.target.value = event.target.value % 10;
    }
  }
  otpPaste(event, index) {
    event.preventDefault();
    this.clipData = event.clipboardData.getData('text/plain').split('');
    this.filldata(event.target.form, index);
  }

  filldata(form, index) {
    for (let i = index; i < 4; i++) {
      form['otp' + i].value = this.clipData.shift();
    }
  }
}
