<div class="wrap_fluid">
    <div class="wrap_content">
        <div class="wrap_container">
            <div class="auth_middle">
                <div class="auth_heading">
                    <div class="brand_icon">
                        <img src="../../assets/img/isg_logo_2024.png">
                    </div>
                    <h1>ISGCON 2024</h1>
                    <h3>Abstract Submission</h3>
                </div>

                <div class="auth_form">
                    <!-- loginId -->
                    <div *ngIf="isUserAlredyExists=== false">
                        <form [formGroup]="validateEmailform" (ngSubmit)="onValidateEmail(validateEmailform.value)">
                            <div class="auth_form_head">
                                <h2>Get Started.</h2>
                                <!-- <p>Login to continue.</p> -->
                            </div>
                            <div class="auth_form_body">
                                <div class="form-outline">
                                    <div class="form-floating">
                                        <input type="email" class="form-control" id="floatingInputEmail" placeholder="Enter your email id" formControlName="Email">
                                        <label for="floatingInputEmail">Enter your email id</label>
                                    </div>
                                    <div class="forms_error_info"
                                        *ngIf="validateEmailform.controls['Email'].invalid && validateEmailform.controls['Email'].dirty">

                                        <span class="error"
                                            *ngIf="validateEmailform.controls['Email'].errors.required">Email
                                            required</span>
                                        <span class="error"
                                            *ngIf="validateEmailform.controls['Email'].errors.pattern && !validateEmailform.controls['Email'].errors.maxlength && !validateEmailform.controls['Email'].errors.minlength">Invalid
                                            email id</span>
                                    </div>

                                </div>
                                <a (click)="onValidateEmail(validateEmailform.value)"
                                    class="btn app_btn btn_secondary">Login / Register</a>
                            </div>
                        </form>
                    </div>
                    <!-- loginId -->

                    <!--password OR OTP-->
                    <div *ngIf="isUserAlredyExists === true">
                        <form [formGroup]="validatePasswordform"
                            (ngSubmit)="onValidatePassword(validatePasswordform.value)">
                            <div class="auth_form_head">
                                <h2>You are already registered!</h2>
                                <p></p>
                            </div>
                            <div class="auth_form_body">
                                <div class="form-outline">
                                    <div class="form-floating">
                                        <input  class="form-control" id="floatingInputPassword" type="password" placeholder="Enter your password" formControlName="Password">
                                        <label for="floatingInputPassword">Enter your password</label>
                                    </div>
                                    <!-- <input type="password" id="form1" class="form-control" formControlName="Password" />
                                    <label class="form-label" for="form1">Enter your password</label> -->
                                    <div class="forms_error_info"
                                        *ngIf="validatePasswordform.controls['Password'].invalid && validatePasswordform.controls['Password'].dirty">
                                        <span class="error"
                                            *ngIf="validatePasswordform.controls['Password'].errors.required">Password
                                            required! </span>
                                        <span class="error"
                                            *ngIf="validatePasswordform.controls['Password'].errors.maxlength">Password
                                            length exceeds </span>
                                    </div>
                                </div>
                                <a (click)="onValidatePassword(validatePasswordform.value)"
                                    class="btn app_btn btn_secondary">Continue</a>

                                <div class="or">
                                    <span>Or</span>
                                </div>

                                <a (click)="loginUsingOtp()" class="btn app_btn btn-outline-dark"
                                    data-mdb-ripple-color="dark">Login Using OTP</a>

                            </div>
                        </form>
                    </div>
                    <!--password OR OTP -->


                    <!-- OTP Verification -->
                    <div *ngIf="isShowOtpVerifyScreen">
                        <div class="auth_form_head">
                            <h2>Enter your 4 digit OTP.</h2>
                            <p></p>
                        </div>
                        <div class="auth_form_body">
                            <form [formGroup]="oneTimePasswordFormGroup"
                                (ngSubmit)="onSubmitOtpFormGroup(oneTimePasswordFormGroup.value)">
                                <div class="form-outline">
                                <div class="opt_grid box_4">
                                    <!-- if OTP count different: use -> box_3, box_5, box_6 - classes-->
                                    <input mdbInput id="otp0" (input)="otpInputs($event,0)" 
                                        (paste)="otpPaste($event,0)"
                                        (keyup)="otpOneCall($event,0)" placeholder="*" type="number" min="0" max="9"   formControlName="input1" class="form-control otp"/>
                                      
                                      
                                        <input mdbInput id="otp1" type="number" (input)="otpInputs($event,1)" (paste)="otpPaste($event,1)"
                                        (keyup)="otpOneCall($event,1)" formControlName="input2" class="form-control otp" placeholder="*" />
                                      
                                      
                                        <input mdbInput id="otp2" type="number" (input)="otpInputs($event,2)" placeholder="*" (paste)="otpPaste($event,2)"
                                        (keyup)="otpOneCall($event,2)" formControlName="input3" class="form-control otp"/>
                                      
                                      
                                        <input mdbInput id="otp3" type="number" (input)="otpInputs($event,3)" placeholder="*" (paste)="otpPaste($event,3)"
                                        (keyup)="otpOneCall($event,3)" formControlName="input4" class="form-control otp"/>
                                      

                                </div>
                                </div>
                                <div class="forms_error_info" *ngIf="showInvalidOtp">
                                    <i class="fa fa-close mr-1"></i>
                                    <span>Please enter valid OTP</span>
                                </div>
                                <a (click)="onSubmitOtpFormGroup(oneTimePasswordFormGroup.value)"
                                    class="btn app_btn btn_secondary">Continue</a>
                            </form>
                        </div>
                    </div>
                    <!-- OTP Verification -->
                </div>
                <div class="auth_btn_block">
                    <div class="stack_btn_group">
                        <a href="javascript:void(0)" class="link_btn" (click)="openAboutMoodal()">About ISGCON 2024</a>
                        <app-guidelines></app-guidelines>
                        <!-- <a href="javascript:void(0)" class="link_btn" data-mdb-toggle="modal" data-mdb-target="#guidlineModal">View Guideline</a> -->
                        <a href="javascript:void(0)" class="link_btn" (click)="openSecretariatModal()">Secretariat</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- App Footer -->
    <footer class="app_footer">
        <div class="footer_flex">
            <p>©ISGCON 2024 - All rights reserved.</p>
            <!-- <ul>
                    <li><a  href="https://www.isshp2023.com/privacy-policy.html">Privacy Policy</a></li>
                    <li><a href="javascript:void(0)">Terms &amp; Conditions</a></li>
                </ul> -->
        </div>
    </footer>
    <!--/. App Footer -->
</div>


<!--About Modal -->
<div class="modal fade" id="aboutModal" bsModal #aboutModal="bs-modal" tabindex="-1"
    aria-labelledby="saveDraftModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="saveDraftModalLabel">About</h5>
                <button type="button" class="btn-close" (click)="aboutModal.hide()" data-mdb-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="static">
                    <div class="static_block">
                        <p>65th&nbsp; Annual Congress of the Indian Society of Gastroenterology, ISGCON 2024, to be held
                            in&nbsp; Varanasi.</p>

                        <p>This conference will have a galaxy of International and National faculty who will share their
                            expertise and&nbsp;knowledge in various areas
                            of&nbsp;<strong>Gastroenterology&nbsp;</strong>like Luminal Disorders, Pancreato - Biliary
                            Diseases,&nbsp;Hepatology, GI Oncology, Interventional GI Endoscopy, GI Physiology, and
                            Basic Sciences.</p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn app_btn btn_close" data-mdb-dismiss="modal"
                    (click)="aboutModal.hide()">Close</button>
            </div>
        </div>
    </div>
</div>
<!--/. About Modal -->

<!--secretariat Modal -->
<div class="modal fade" id="secretariatModal" bsModal #secretariatModal="bs-modal" tabindex="-1"
    aria-labelledby="saveDraftModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="saveDraftModalLabel">Secretariat</h5>
                <button type="button" class="btn-close" (click)="secretariatModal.hide()" data-mdb-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="static">
                    <div class="static_block">
                        <h6>Indian Society of Gastroenterology</h6>

                        <p><strong><em>For queries regarding abstract, email to:</em></strong></p>

                        <p><a href="mailto:secretary@isg.org.in">secretary@isg.org.in</a></p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn app_btn btn_close" data-mdb-dismiss="modal"
                    (click)="secretariatModal.hide()">Close</button>
            </div>
        </div>
    </div>
</div>
<!--/. secretariat Modal -->

<script src="https://code.jquery.com/jquery-3.6.1.min.js"></script><!-- jQuery -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/6.0.1/mdb.min.js" type="text/javascript"></script>